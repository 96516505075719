import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingSpinnerService } from '@modules/shell/services/loading-spinner.service';
import { tap, finalize } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { InternalCurrentCustomerService } from '@modules/shell/services/internal-current-customer.service';
import { SnackbarComponent } from '@modules/shell/components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private internalCurrentCustomerService: InternalCurrentCustomerService,
    private oidcSecurityService: OidcSecurityService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }
  excludeSpinner: boolean = false;
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('Message/unread') > -1) {
      this.excludeSpinner = true;
    } else {
      // We need to reset this variable on each request if not messages
      this.excludeSpinner = !this.loadingSpinnerService.isSpinnerEnabeled;
    }
    if (!this.excludeSpinner) {
      this.loadingSpinnerService.addSpinner();
      this.SetSessionTime();
    }
    let customReq = request.clone();
    const currentUrl = new URL(request.url);
    if (currentUrl.pathname.indexOf('/api/') === 0) {
      let token = "";
      this.oidcSecurityService.getAccessToken().subscribe(t => token = t);

      const customerId = this.internalCurrentCustomerService.getCustomerId();
      customReq = request.clone({
        headers: request.headers
          .append('customerId', customerId)
          .append('Authorization', 'Bearer ' + token)
          .append('Access-Control-Allow-Origin', '*'),
      });
    }

    let ok: any;
    return next.handle(customReq).pipe(
      tap(
        (event) => (ok = event instanceof HttpResponse ? 1 : 0),
        (error: HttpErrorResponse) => {
          switch (error.status) {
            case 401:
              this.oidcSecurityService.logoff();
              this.router.navigate(['/login']);
              break;
            default: {
              this.openSnackBar(error);
            }
          }
        }
      ),
      finalize(() => {

        // Don't interrupt long http calls
        if (!(request.url.indexOf('Message/unread') > -1)) {
          this.loadingSpinnerService.removeSpinner();
        }
      })
    );
  }

  SetSessionTime() {
    const now: Date = new Date();
    sessionStorage.setItem('session', now.toISOString());
  }

  openSnackBar(error: HttpErrorResponse) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 60000,
      panelClass: ['snackbar', 'red'],
      data: {
        message: 'Error occured: ' + error.message,
        action: 'Ok',
        error: '' + error.error,
      },
    });
  }
}
