import { C } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InformationDialogService } from '@modules/shell/services/information-dialog.service';
import {
  AuthConfig,
  OAuthErrorEvent,
  OAuthService,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { ROUTENAMES } from 'src/app/app-routing.names';
import { SystemInfoDto, SystemService } from 'src/app/services/system.service';

@Injectable({
  providedIn: 'root',
})
export class OpenIDCService {
  private MESSAGE_SESSION_ENDED = 'Din session er udløbet. Du skal logge ind igen for at fortsætte.';
  private MESSAGE_AN_ERROR_OCCURRED = 'Der er sket en fejl ved login!';

  private systemInfo: SystemInfoDto;
  private isAuthenticated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();

  constructor(
    private systemService: SystemService,
    private oAuthService: OAuthService,
    private informationService: InformationDialogService,
    private router: Router
  ) {}

  init() {
    return new Observable((subscriber) => {
      // HTTP Get call
      this.systemService.systemInfo$.subscribe(
        (info) => {
          this.systemInfo = info;
          this.oAuthService.configure(this.getConfig(info));

          // extra debug information, if turned on in getConfig
          if (this.oAuthService.showDebugInformation) {
            this.oAuthService.events.subscribe((event) => {
              console.log(event);
            });
          }

          this.oAuthService
            .loadDiscoveryDocumentAndTryLogin()
            .then((loaded) => {
              subscriber.complete();
            })
            .catch((error) => console.error(error));

          this.oAuthService.setupAutomaticSilentRefresh();
          this.oAuthService.events.subscribe((event) => {
            this.setIsAuthenticated(this.oAuthService.hasValidAccessToken());

            if (event instanceof OAuthErrorEvent) {
              // to debug error message add a letter to issuer in getConfig
              var currentEvent = event as OAuthErrorEvent;

              switch (event.type) {
                case 'token_refresh_error':
                  this.informationService.showErrorMessage({
                    title: this.MESSAGE_SESSION_ENDED,
                  });
                  this.router.navigate([ROUTENAMES.LOGIN]);
                  break;
                default:
                  this.informationService.showErrorMessage({
                    messages: [event.type, event.reason['message']],
                    title: this.MESSAGE_AN_ERROR_OCCURRED,
                  });
                  break;
              }
            }
          });
        },
        (error) => {
          subscriber.error();
        }
      );
    });
  }

  login() {
    this.oAuthService.initCodeFlow();
  }

  logout() {
    this.oAuthService.logOut({
      client_id: this.systemInfo.clientId,
    });
  }

  // only set it if it has changed
  private setIsAuthenticated(value: boolean) {
    if (this.isAuthenticated.getValue() !== value) {
      this.isAuthenticated.next(value);
    }
  }

  private getConfig(info: SystemInfoDto): AuthConfig {
console.log(info);

    return {
      issuer: info.issuer,
      skipIssuerCheck: true,
      timeoutFactor: 0.9,
      redirectUri: window.location.origin,
      clientId: info.clientId,
      customQueryParams: {
        domain_hint: this.checkOverwriteDomainHint(info),
      },
      oidc: true,
      responseType: 'code',
      scope: info.scope,
      showDebugInformation: false,
    };
  }

  private checkOverwriteDomainHint(info: SystemInfoDto) {
    let domain_hint = info?.domainHints['default'] || '';
    const params = new URLSearchParams(window.location.search);
    // Looping all url parameters to find the domain hint one
    params.forEach(function (value, key) {
      if (key === 'domain_hint' && info.domainHints[value]) {
        domain_hint = info.domainHints[value];
      }
    });
    return domain_hint;
  }
}
